import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const AccountNav = ({ path }) => {
  return (
    <nav className="account-nav-list page-block">
      <ul className="list-style-none flex align-center">
        <li className={path === "order-listing" ? "active" : null}>
          <Link to="/order-list">Orders</Link>
        </li>
        <li className={path === "addresses" ? "active" : null}>
          <Link to="/addresses">Addresses</Link>
        </li>
        <li>
          <Link to="/">
            Wish Lists <span>(2)</span>
          </Link>
        </li>
        <li className={path === "account-settings" ? "active" : null}>
          <Link to="/account-settings">Account Settings</Link>
        </li>
      </ul>
    </nav>
  );
};

AccountNav.propTypes = {
  path: PropTypes.string,
};

export default AccountNav;

import { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Api from "../../services/Api";

async function addAddress(url, data) {
  const response = await Api.post(url, data);
  return response.data.data;
}

async function updateAddress(url, data) {
  const response = await Api.put(url, data);
  return response.data.data;
}

function useCreateUpdateCustomerAddress() {

  const [customer, setCustomer] = useState();
  useEffect(() => {
    setCustomer(JSON.parse(sessionStorage.getItem("customerId")));
  }, []);

  const createUpdateCustomerAddress = async (data, address_id) => {
    try {
      const fields = {
        ...(address_id && {
          id: address_id,
        }),
        first_name: data.first_name,
        last_name: data.last_name,
        ...(!address_id && {
          customer_id: customer.response.customer_id,
        }),
        country_code: "US",
        company: data.company,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state_or_province: data.state_or_province,
        postal_code: data.postal_code,
        phone: data.phone,
      };
      // Update Address.
      if (address_id) {
        await updateAddress(
          customer && customer.response
            ? `${
              process.env.NETLIFY_API_BASE_URL
            }?endpoint=customers/addresses`
            : null,
          [fields]
        );
        navigate("/addresses");
      } else {
        // Add Address.
        await addAddress(
          customer && customer.response
            ? `${
              process.env.NETLIFY_API_BASE_URL
            }?endpoint=customers/addresses`
            : null,
          [fields]
        );
        navigate("/addresses");
      }
    } catch (e) {
      console.log(e);
    }
  };
  return { createUpdateCustomerAddress };
}

export default useCreateUpdateCustomerAddress;

import React from "react";
import Select from "../components/form/Select";
import TextField from "../components/form/TextField";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
  getFormError,
  requiredMsg,
  zipcodeRegExp,
  zipcodeRegMsg,
} from "../utils/form";
import { createOption } from "../utils/components";
import useGetAllStates from "../hooks/useGetAllStates";
import useCreateUpdateCustomerAddress from "../hooks/customer/useCreateUpdateCustomer";
import { navigate } from "gatsby";
import Layout from "../layout/Layout";
import AccouintNav from "../components/account/account-nav";

const COUNTRY_OPTIONS = [createOption("US", "United States")];

const validationSchema = object().shape({
  first_name: string().required(requiredMsg("first name")),
  last_name: string().required(requiredMsg("last name")),
  address1: string().required(requiredMsg("Address line 1")),
  zip_code: string().matches(zipcodeRegExp, zipcodeRegMsg),
});

const initialValues = {
  country: "United States",
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  company: "",
  state_or_province: "",
  city: "",
  postal_code: "",
  phone: "",
};

function CreateUpdateAddress({ location }) {
  const { createUpdateCustomerAddress } = useCreateUpdateCustomerAddress();

  const savedValues = location && location.state;

  const formik = useFormik({
    initialValues: savedValues || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createUpdateCustomerAddress(values, location.state.id);
    },
    enableReinitialize: true,
  });

  const { STATE_OPTIONS } = useGetAllStates();

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <h1 className="page-title">Addresses</h1>
        </div>
      </div>
      <div className="container">
        <AccouintNav path={"addresses"} />
        <div className="container">
          <form
            className="form create-account-form form-mid"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-row form-row--half">
              <div className="form-field">
                <Select
                  id={"customer-country"}
                  name={"country"}
                  onChange={formik.handleChange}
                  placeholder="Select"
                  options={COUNTRY_OPTIONS}
                  value={formik.values}
                  label={"Country"}
                  labelFor={"select-country"}
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-first-name"}
                  name={"first_name"}
                  label={"First Name"}
                  labelFor={"first-name"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["first_name"]}
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  error={getFormError(formik.errors, "first_name")}
                  required
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-last-name"}
                  name={"last_name"}
                  label={"Last Name"}
                  labelFor={"last-name"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["last_name"]}
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  error={getFormError(formik.errors, "last_name")}
                  required
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-address1"}
                  name={"address1"}
                  label={"Address Line 1"}
                  labelFor={"address-line1"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["address1"]}
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                  error={getFormError(formik.errors, "address1")}
                  required
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-address2"}
                  name={"address2"}
                  label={"Address Line 2"}
                  labelFor={"address-line2"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["address2"]}
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                  error={getFormError(formik.errors, "address2")}
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-company-name"}
                  name={"company"}
                  label={"Company Name"}
                  labelFor={"compnay-name"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["company"]}
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  error={getFormError(formik.errors, "company")}
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-city"}
                  name={"city"}
                  label={"Suburb/City"}
                  labelFor={"city"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["city"]}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  error={getFormError(formik.errors, "city")}
                />
              </div>

              <div className="form-field">
                <Select
                  id={"customer-state"}
                  name={"state_or_province"}
                  onChange={formik.handleChange}
                  placeholder="Choose a state"
                  options={STATE_OPTIONS}
                  value={formik.values}
                  label={"State/Province"}
                  labelFor={"select-state"}
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-postal-code"}
                  name={"postal_code"}
                  label={"Zip/Postcode"}
                  labelFor={"postal-code"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["postal_code"]}
                  onChange={formik.handleChange}
                  value={formik.values.postal_code}
                  error={getFormError(formik.errors, "postal_code")}
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"customer-phone"}
                  name={"phone"}
                  label={"Phone Number"}
                  labelFor={"phone-number"}
                  type={"input"}
                  onBlur={formik.handleBlur}
                  touched={formik.touched["phone"]}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  error={getFormError(formik.errors, "phone")}
                />
              </div>
            </div>

            <div className="form-actions text-center">
              <button type="submit" className="button">
                Save Address
              </button>
            </div>
            <div className="form-actions text-center">
              <button
                className="button button--secondary button-small"
                onClick={() => navigate("addresses")}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default CreateUpdateAddress;

import React from "react";
import PropTypes from "prop-types";

const Select = ({
  id,
  name,
  options,
  onChange,
  value,
  placeholder,
  label,
  labelFor,
  required,
}) => {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={labelFor}>
          {label}
          {required && <small>REQUIRED</small>}
        </label>
      )}
      <select
        className="form-select"
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value[name]}
        required={required}
      >
        {options &&
          options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
      </select>
    </>
  );
};

export const Option = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.any,
});

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(Option).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  required: PropTypes.bool,
};

Select.displayName = "Select";

export default Select;

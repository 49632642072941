import useSWR from "swr";
import Api from "../services/Api";
import { createOption } from "../utils/components";

function useGetAllStates() {
  const STATE_OPTIONS = [];

  const country_id = 226; // Only US states.

  const getKey = () => {
    return `${process.env.NETLIFY_API_BASE_URL}?endpoint=countries/${country_id}/states?limit=250`;
  };

  const { data, error } = useSWR(() => getKey(), getStates);

  const isLoading = !data && !error;

  if (data?.length) {
    data.forEach((state) =>
      STATE_OPTIONS.push(createOption(state.state, state.state))
    );
  }

  return { STATE_OPTIONS, isLoading };
}

async function getStates(url) {
  const response = await Api.get(url);
  return response.data;
}

export default useGetAllStates;
